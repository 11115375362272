import React from 'react';
import './Join.css'; // Ensure you import the CSS file
// import Button from 'react-bootstrap/Button';
// import { Link } from 'react-router-dom';

function AssociatedExpert() {
  return (
    <div className="associated-expert-page">
      <div className="content">
        <h2 className="title">Joining TrustBroker Africa as TBA Associated expert</h2>
        <p>
          This status will only apply in the future as currently there are no TBA Accredited teams!
          </p>
          <p>TBA Associates are individuals whose experience and/or skills can be of clear benefit 
            to the TBA Community, but who are no longer a member of an TBA Accredited team and thus cannot 
            contribute through their team any longer.</p>
          <p>The TBA Associates Policy outlines the process and rules related to becoming a TBA Associate 
            for the first time and the required renewals.</p>
            <p>At least two (2) individuals from the TBA community, from different entities, need to 
              jointly nominate a candidate. After the nominations and the interest of the nominee are confirmed, 
              the TBA community is asked for any formal support. A minimum of five (5) accredited teams need to 
              formally support the nomination and there must be no objections. After three weeks the TBA Steering Committee 
              will decide on the acceptance and outcome of the nomination process. There are no fees to be paid by TBA Associates.
        </p>
      </div>
      <div className="image-container">
        <img src="/assets/images/tba_associating.png" alt="Placeholder" className="placeholder-image" />
      </div>
    </div>
  );
}

export default AssociatedExpert;
