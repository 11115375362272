import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import './Registry.css';
import TeamsDirectory from './TeamsDirectory';
import RecentDatabase from './RecentDatabase';
import UpdateHistory from './UpdateHistory';
// import CapacityBuilding from './CapacityBuilding'

function Registry() {
  const [view, setView] = useState('teams-database');

  let title

  if(view === 'teams-database') {
    title = 'Team Directory';
  } else if (view === 'recent-database') {
    title = 'Recent Database';

  }
  else if (view === 'update-history') {
    title = 'Update History';

  }


  const renderContent = () => {
    switch (view) {
      case 'teams-database':
        return (
         <TeamsDirectory />
        );
      case 'recent-database':
        return (
          <RecentDatabase />
        );
      case 'update-history':
        return (
         <UpdateHistory />
        );
      default:
        return null;
    }
  };



  return (
    <div className="registry-container">
      <h2 className="title">{title}</h2>
      <div className="tabs">
        <Button className="tab" onClick={() => setView('teams-database')}>Teams Database</Button>
        <Button className="tab" onClick={() => setView('recent-database')}>Recent Updates</Button>
        <Button className="tab" onClick={() => setView('update-history')}>Update History</Button>
      </div>
      <div className="content">
        {renderContent()}
      </div>
    </div>
  );
}

export default Registry;
