import React from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaComments} from "react-icons/fa";
import "./Footer.css"; 
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer-container">
  <div className="footer-main">
  <div className="footer-content">
        <div className="footer-section">
          <img
            src="/assets/images/TBAFinal.png"
            alt="About TBA"
            className="footer-image"
          />
          <div className="footer-bottom">
        <div className="footer-privacy">
          <p>Privacy</p>
          <p>Disclaimer</p></div>
        </div>
        </div>

        <div className="footer-section">
          <h4>Get in touch</h4>
          <ul>
        <li>TrustBroker Africa Service</li>
         <li>c/o WACREN</li>
         <li>VCG Office Complex, IPS Road</li>
         <li>P O Box LG 1279, Accra, Ghana</li>
         </ul>
        </div>

        <div className="footer-section">
          <h4>Important links</h4> 
          <ul>
            <li>
              <Link to ="/about/trustbroker-africa" className="link">About TBA</Link>
            </li>
            <li>
              <Link to="/community/media" className="link">Latest news</Link>
            </li>
            <li>
              <Link to="/upcomingevents" className="link">Upcoming events</Link>
            </li>
            <li>
              <Link to="/join" className="link">Get involved</Link>
            </li>
          </ul>
        </div>
        
        </div>
       
      
        
        </div>
        
        <div className="footer-partners">
          <h4>Partners</h4>
          {/* <div className="partner-logos"> */}
            {/* <a
              href="https://www.wacren.net/"
              target="_blank"
              rel="noopener noreferrer"
                >
              <img
                src="/assets/images/WACREN.jpeg"
                alt="WACREN"
                className="partner-logo"
              />
            </a>
            <a
              href="https://www.opencsirt.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/assets/images/opencsirt.jpeg"
                alt="Open CSIRT Foundation"
                className="partner-logo"
              />
            </a> */}
            {/* <a
              href="https://www.ac3.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/assets/images/AC3_logo.png"
                alt="AC3 Logo"
                className="partner-logo"
              />
            </a>
            <a
              href="https://www.europa.eu/"
              target="_blank"
              rel="noopener noreferrer" >
              <img
                src="/assets/images/eu_flag.png"
                alt="EU Logo"
                className="partner-logo"
              />
              
            </a> */}

            
            <img
            src="/assets/images/footerlogo.jpeg"
            alt="partnerslogo"
            className="partner-logo"
          />
          <div>&nbsp;</div>
          {/* </div> */}
          
        {/* </div> */}
      </div>
      {/* <div className="footer-bottom2"></div>
      <a href="#top" className="scroll-to-top"> ^</a> */}
      {/* <div className="socials"> */}
      {/* <div className="social-icons"> */}
            {/* <a href="https://facebook.com">
              <FaFacebook/>
            </a> */}
            {/* <a href="https://twitter.com">
              <FaTwitter />
            </a> */}
            {/* <a href="https://instagram.com">
              <FaInstagram />
            </a>
          
          <a href="/chat">
            <FaComments />
          </a> */}
        {/* </div> */}
        {/* </div> */}
      
     
        
        {/* </div> */}
        
    
      
      
      

      <div className="footer-bottom1">
          <p>Copyright 2024. TBA. All rights reserved</p>
        </div>
   
   
    </footer>

  );
}

export default Footer;



