

import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FaDatabase, FaFolderOpen, FaSync, FaHistory } from "react-icons/fa";
import "./App.css";

// ImageSlider Component
const ImageSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div style={{ width: "1000px", margin: "0 auto" }}>
      <Slider {...settings}>
        <div>
          <img
            src="/assets/images/sim3slider2.jpeg"
            alt="Slide 1"
            style={imageStyle}
          />
        </div>
        <div>
          <img
            src="/assets/images/sim3slider.jpeg"
            alt="Slide 2"
            style={imageStyle}
          />
        </div>
        <div>
          <img
            src="/assets/images/image2.png"
            alt="Slide 3"
            style={imageStyle}
          />
        </div>
      </Slider>
    </div>
  );
};

// Styles for the images in the slider
const imageStyle = {
  maxWidth: "90%",
  // height: "auto",
  borderRadius: "8px",
};

// Home Component
function Home() {
  return (
    <div className="home">
      <ImageSlider />

      <div>
        <h2 className="intro-text">Welcome to TrustBroker Africa</h2>
        <div className="text-intro">
          <h5>Services for Security and Incident Response Teams in Africa</h5>

          <div className="text-content">
            <p>
              Security and Incident Response teams including SOCs, PSIRTs, ISACs
              and certainly CSIRTs manage the handling of information security
              incidents as well as actionable information about vulnerabilities
              and threats within their organisation, product line or networks -
              their tasks broadly range from prevention and awareness-raising,
              via incident detection to the actual tracking and resolving of
              incidents and drawing lessons from that.
            </p>
            <p>
              The TrustBroker Africa Service forms the trusted backbone of
              infrastructure services and serves as a clearinghouse for all
              security and incident response teams. It lists well-known teams
              and accredits those teams according to their demonstrated support
              of the common goals and adherence to the established practices
              governing the TBA community. Vital member-only services enabling
              security and incident response teams to interact more efficiently
              and effectively with each other are available to all accredited
              teams.
            </p>
            <p>
              Some of these services are made available to the public to further
              improve and facilitate the interactions of affected users and
              organisations with the "right" team. Most namely, TBA provides a
              full directory of contacts and other useful information about all
              listed and accredited teams once we conclude our first phase of
              connecting all interested parties and inviting them to{" "}
              <Link to="/join" className="link">
                join!
              </Link>
            </p>
            <p>
              Becoming a listed team is a mandatory requirement and the first
              step in order to become accredited and thereby getting access to
              the member's only services.
            </p>
            <p>
              If you are interested in the TBA services or framework, or if you
              have a specific question regarding the cooperation and
              coordination of security and incident response teams in Africa and
              beyond,{" "}
              <Link to="/support" className="link">
                please do not hesitate to contact us.
              </Link>
            </p>
          </div>
        </div>
      </div>

      <div className="join-section">
        <h2 className="join-text">Joining TrustBroker Africa</h2>
        <p>
          Learn more about how you can join TrustBroker Africa and become part
          of our growing community. We offer various membership options to cater
          to different needs and interests. Whether you are a listed team, an
          accredited team, or an associated expert, there's a place for you at
          TrustBroker Africa.
        </p>
        <Link to="/join">
          <Button variant="primary">Join us</Button>
        </Link>
      </div>

      <div className="registry-container">
        <h2 className="registry-title">Registry</h2>
        <div className="registry-icons">
          <div className="registry-item">
            <Link to="/registry">
              <FaDatabase className="icon" />
              <p className="icon-label">Directory</p>
            </Link>
          </div>
          <div className="registry-item">
            <Link to="/registry">
              <FaFolderOpen className="icon" />
              <p className="icon-label">Team Database</p>
            </Link>
          </div>
          <div className="registry-item">
            <Link to="/registry">
              <FaSync className="icon" />
              <p className="icon-label">Recent Update</p>
            </Link>
          </div>
          <div className="registry-item">
            <Link to="/regis">
              <FaHistory className="icon" />
              <p className="icon-label">Update History</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
