import React from 'react';
// import "bootstrap/dist/css/bootstrap.min.css";
// import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import { FaFacebook, FaTwitter, FaInstagram, FaComments } from "react-icons/fa";



function MainNavbar() {
   return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" style={{position: "fixed", zIndex: 9999, width: '100%', paddingLeft: 20,}}>
    {/* <Container> */}
      <Navbar.Brand as={Link} to="/">
       <img src='/assets/images/TBAFinal.png' alt='tba logo' style={{width: "40%"}} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <NavDropdown title="About TBA" id="collapsible-nav-dropdown">
            <NavDropdown.Item as={Link} to="/about/trustbroker-africa">
              TrustBroker Africa
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/about/steering-committee">
              Steering Committee
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/about/resources">
              Resources
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={Link} to="/registry">
            Registry
          </Nav.Link>
          <Nav.Link as={Link} to="/join">
            Join
          </Nav.Link>
          <NavDropdown title="Community" id="collapsible-nav-dropdown">
            <NavDropdown.Item as={Link} to="/community/media">
             Media
            </NavDropdown.Item>
            {/* <NavDropdown.Item as={Link} to="/community/blog">
             Blog
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/community/giz-programme">
             GIZ Programme
            </NavDropdown.Item> */}
            </NavDropdown>
          {/* <Nav.Link as={Link} to="/media">
            Media
          </Nav.Link> */}
          <Nav.Link as={Link} to="/support">
            Support
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      {/* <div className="socials"> */}
      {/* <div className="social-icons"> */}
            {/* <a href="https://facebook.com">
              <FaFacebook/>
            </a> */}
            {/* <a href="https://twitter.com">
              <FaTwitter />
            </a> */}
            {/* <a href="https://instagram.com">
              <FaInstagram />
            </a>
          
          <a href="/chat">
            <FaComments />
          </a> */}
      {/* </div> */}
      {/* </div> */}
      
    {/* </Container> */}
  </Navbar>
   )
}

export default MainNavbar