


import React, { useState } from 'react';
import './Join.css'; // Ensure you import the CSS file
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';

function Join() {
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);

  const handleReadMoreClick = () => {
    setShowMore(!showMore);
  };

  const navigation = (url) => {
    navigate(url);
  };

  const styles = {
    mainList: {
      listStyleType: 'disc',
      marginLeft: '20px',
      marginBottom: '10px',
    },
    subList: {
      listStyleType: 'circle',
      marginLeft: '20px',
    
    },
  };

  return (
    <div className="join-page">
      <h1>Join TrustBroker Africa</h1>
      <p>
        Security and Incident Response teams manage the handling of information security incidents within their organisation or 
        network - their tasks broadly range from prevention and awareness raising, via incident detection to the actual tracking and resolving of incidents and drawing lessons from that. 
        The TrustBroker Africa - a.k.a. TBA - was developed in 2020 to address common needs and build a service infrastructure providing 
        vital support for all security and incident response teams from Africa. The processes to join the TBA community are aligned with those of the{' '}
        <a href="https://www.trusted-introducer.org/" target="_blank" rel="noopener noreferrer">
          TI Service for Europe
        </a>{' '}
        to provide a compatible approach and common understanding for both the African and European community.
      </p>
      <p>
        To safeguard the trusted environment a comprehensive set of processes have been adopted, outlining what it takes to participate and maintain a team's status within the TBA community. This requires a long-term commitment of the participating teams and individuals and the TBA processes emphasize the need for continuous improvement and maintenance as regular tasks.
      </p>
      
      {showMore && (
        <div className="additional-content">
          <p>The TBA service differentiates between three categories:</p>
          <ul style={styles.mainList}>
            <li>
              teams are
              <ul style={styles.subList}>
                <li><em>listed</em>, which provides basic information about the team itself as well as shows endorsement of the team by the TBA community;</li>
                <li><em>accredited</em>, which ensures a defined level of best practices and acceptance of the established TBA policies for such teams;</li>
                <li><em>security experts</em> can participate as TBA Associates.</li>
              
      <p>The TBA Accreditation requires regular efforts to maintain the team's status. 
        Such efforts are also expected from TBA Listed teams. To ensure a high level of trust within the TBA community,
         listed teams that have not become accredited within three years are required to demonstrate the continuous support of the listing by the TBA community. 
         This is called "re-listing". Therefore the directory of the TBA service can be trusted to reflect the actual and accurate snapshot of all teams shown regardless of their status.
      </p>
      <p>Although many years have passed since the first team was created back in 1988 at the Carnegie Mellon University / SEI (Pittsburgh, PA, USA), 
        only very few standards apply to these processes. To provide a consistent overview for anyone interested, the different de-facto standards{' '} 
        <a href="https://www.trusted-introducer.org/processes/standards.html" target="_blank" rel="noopener noreferrer">
       
        are explained here
        </a>.{' '}
      </p>
     
      </ul>
            </li>
          </ul>
        </div>
      )}
       <Button className='dflex items-center justify-center text-center mb-4 btn' onClick={handleReadMoreClick}>
        {showMore ? 'Show Less' : 'Read More'}
      </Button>

     
          
      {/* <h3 className='mt-4 text-center'>Join TBA as:</h3> */}
      <div className='card-container'>
        <div className="card-main">
          <div className="card__content">
            <h2 className="card__title">TBA Listed team</h2>
            <div className="card__img">
              <img src="assets/images/listed.jpeg" alt="TBA Listed team" />
            </div>
          </div>
          <Button onClick={() => navigation("/join/listed-team")} className="button">
            Join
          </Button>
        </div>
        <div className="card-main">
          <div className="card__content">
            <h2 className="card__title">TBA Accredited team</h2>
            <div className="card__img">
              <img src="assets/images/accredited.jpeg" alt="TBA Accredited team" />
            </div>
          </div>
          <Button onClick={() => navigation("/join/accredited-team")} className="button">
            Join
          </Button>
        </div>
        <div className="card-main">
          <div className="card__content">
            <h2 className="card__title">TBA Associated expert</h2>
            <div className="card__img">
              <img src="assets/images/associated.jpeg" alt="TBA Associated expert" />
            </div>
          </div>
          <Button onClick={() => navigation("/join/associated-team")} className="button">
            Join
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Join;
