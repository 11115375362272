
// import React, { useState } from 'react';
// import './About.css'; 

// function SteeringCommittee() {
//   const committeeMembers = [
//     { 
//       name: 'David Mopane', 
//       description: `David Mopane is a leading figure in Botswana's cybersecurity landscape, known for his extensive expertise in digital security, risk management, and cyber defense.
//       As the founder and CEO of Cybersmart Botswana, David has spearheaded efforts to enhance the cybersecurity resilience of businesses, government institutions, and individuals across the country.`, 
//       fullDescription: `With over a decade of experience in the field, David Mopane has established Cybersmart Botswana as a premier provider of cutting-edge cybersecurity solutions. 
//       His work focuses on safeguarding critical infrastructure, 
//       improving information security frameworks, and offering tailored security training and consultancy services to public and private clients.
//     David is passionate about building local capacity in cybersecurity. He has been instrumental in training cybersecurity professionals, raising awareness about online 
//     threats, and promoting best practices in digital security across Botswana. 
//     Recognising the importance of robust regulatory frameworks, David actively contributes to developing national cybersecurity policies. He collaborates with government 
//     agencies and regional bodies to create guidelines that address emerging cyber threats and enhance national security.`, 
//       image: '/assets/images/David Mopane.jpg' 
//     },
//     { 
//       name: 'Cedric Mbeyet', 
//       description: `Cedric Mbeyet is the AFRINIC delegate in the TBA steering committee. He is a distinguished network engineer and expert in Internet governance. 
//       Through his role at AFRINIC (African Network Information Centre), the Regional Internet Registry (RIR) for Africa, Cedric is currently contributing to the development of Africa's Internet infrastructure. `, 
//       fullDescription: `He is actively involved in ensuring the efficient allocation and management of IP resources across Africa. His work at AFRINIC helps organisations, 
//       service providers, and governments have the resources to connect to and grow within the global Internet infrastructure. Cedric is passionate about building technical capacity across the continent. 
//       He regularly conducts workshops and training sessions to help engineers, policymakers, and technical staff enhance their understanding of IP networks, IPv6 adoption, and Internet security, contributing 
//       to the overall advancement of Africa’s Internet capabilities.
//       Cedric Mbeyet is also a respected voice in the African Internet community. He often participates in regional and international forums on topics such as Internet governance, cybersecurity, and digital inclusion. 
//       His thought leadership has helped raise awareness about Africa's need for sustainable Internet policies and infrastructure development.`, 
//       image: '/assets/images/Cedric.jpeg' 
//     },
    
//     { 
//       name: 'Dr Lourino Chemane', 
//       description: `Lourino Alberto Chemane is a Professor of computer systems at Eduardo Mondlane University in Mozambique. 
//       He has worked in Information and Communication Technology for more than 30 years. 
//       He has been the Chairman of the Board of the National Institute of Information and Communication Technology (INTIC) since April 2021`, 
//       fullDescription: `Laourino sits on the Board of UbuntuNet Alliance. From 2010 to 2021, he was the ICT Adviser to the Minister of Science, 
//       Technology and Higher Education of Mozambique and the CEO of MoRENet (Mozambique Research and Education Network) from 2016 to 2021. 
//       From 2010 to 2016, he was the Coordinator of the Mozambique eGovernment and Communication Infrastructure Project (MEGCIP). 
//       He holds a PhD Degree (2011) in Computer and Systems Science from Stockholm University, a Master’s Degree (2007) in eGovernance from the Ecole Polytechnique Fédérale de Lausanne (EPFL), 
//       a Master’s Degree (1999) in Electronics Engineering (MEng) from the University of Pretoria, and an undergraduate degree in electronic engineering from Eduardo Mondlane University. 
//       He also holds a Diploma (2018) in Contemporary Diplomacy with Specialization in Internet Governance from the University of Malta, is a member of the first generation of the Emerging African Innovation Leaders Programme, 
//       graduated by Politecnico di Milano and Politecnico di Torino in 2019, and completed the European Union Cyber Diplomacy (EU-CD) Scholarship Programme with the European Union Institute for Security Studies (EUISS) in 2023.`, 
//       image: '/assets/images/Lourino.jpg' 
//     },
//     { 
//       name: 'Omo-Oaiya', 
//       description: `Omo is WACREN’s Chief Strategy Officer (CSO). With over 20 years of experience in the African NREN ecosystem, 
//       Omo has played a pivotal role in WACREN's development and strategy. He coordinates the LIBSENSE initiative, enhancing local and 
//       national services supporting open science and research in Africa.`, 
//       fullDescription: `Previously, as Chief Technology Officer, Omo oversaw the management of technical assets and the development of 
//       the regional network backbone from its inception. Before joining WACREN, he was CEO of Datasphir Solutions Limited, a private-sector consultancy company. 
//       A certified SIM3 Auditor, he uses the framework to evaluate the governance, performance, and function of security teams and processes.`,
//       image: '/assets/images/Omo.jpg' 
//     },
    
//     { 
//       name: 'Perpertus-Houngbo', 
//       description: `Jacques Houngbo is a seasoned cybersecurity and digital transformation specialist known for his deep expertise 
//       in protecting information systems and leading strategic digital initiatives across West Africa. Over the past 15 years, 
//       Jacques has made significant contributions to enhancing the security posture of organisations in both the public and private sectors.`, 
//       fullDescription: `Jacques focuses on developing resilient cybersecurity frameworks that safeguard critical infrastructure from evolving cyber threats. 
//       His work has involved consulting for governments, international organisations, and corporations, providing guidance on cybersecurity best practices, risk management, 
//       and integrating cutting-edge technologies to improve digital security.Jacques has played an integral role in training and mentoring a new generation of 
//       cybersecurity professionals, particularly in West Africa, where the demand for skilled experts is rapidly growing. An active participant in regional and international forums, 
//       Jacques regularly speaks on cyber defense, digital infrastructure development, and the importance of cybersecurity awareness. 
//       His thought leadership contributes to a more secure digital future for Africa as it embraces the opportunities of the digital age.`, 
//       image: '/assets/images/Perpetus.jpg' 
//     },
    
//   ];

//   // Create a state to manage which "read more" section is expanded
//   const [expanded, setExpanded] = useState(null);

//   const toggleReadMore = (index) => {
//     setExpanded(expanded === index ? null : index);  // Toggle expansion
//   };

//   return (
//     <div className="steering-committee-container">
//       <h2 className="headline">Steering Committee</h2>
//       <div className="committee-members">
//         {committeeMembers.map((member, index) => (
//           <div key={index} className="member-card">
//             <img src={member.image} alt={`${member.name}`} />
//             <div className="member-name">
//             <h3>{member.name}</h3>
//             <p>{member.description}</p>
            
//             {expanded === index && (
//               <div className="full-description">
//                 <p>{member.fullDescription}</p>
//               </div>
//             )}

//             <button 
//               className="read-more" 
//               onClick={() => toggleReadMore(index)}>
//               {expanded === index ? "Show less" : "Read more"}
//             </button>
//           </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default SteeringCommittee;



import React from 'react';
import './About.css'; 

function SteeringCommittee() {
  const committeeMembers = [
    { 
      name: 'David Mopane', 
      description: `David Mopane is a leading figure in Botswana's cybersecurity landscape, 
      known for his extensive expertise in digital security, risk management, and cyber defense.`, 
      // linkedin: 'https://www.linkedin.com/in/mangwajane-david-mopane-8273392b7/?originalSubdomain=za/', 
      image: '/assets/images/David Mopane.jpg' 
    },
    { 
      name: 'Cedric Mbeyet', 
      description: `Cedric Mbeyet is the AFRINIC delegate in the TBA steering committee. 
      He is a distinguished network engineer and expert in Internet governance.`, 
      linkedin: 'https://www.linkedin.com/in/cmbeyet/?originalSubdomain=mu/', 
      image: '/assets/images/Cedric.jpeg' 
    },
    { 
      name: 'Dr Lourino Chemane', 
      description: `Lourino Alberto Chemane is a Professor of computer systems at 
      Eduardo Mondlane University in Mozambique.`, 
      linkedin: 'https://www.linkedin.com/in/lourino-chemane-b64547106/?originalSubdomain=mz/', 
      image: '/assets/images/Lourino.jpg' 
    },
    { 
      name: 'Omo-Oaiya', 
      description: `Omo is WACREN’s Chief Strategy Officer (CSO). 
      A certified SIM3 Auditor, he uses the framework to evaluate the governance, 
      performance, and function of security teams and processes.`, 
      linkedin: 'https://www.linkedin.com/in/omooaiya/', 
      image: '/assets/images/Omo.png' 
    },
    {
      name: `Open CSIRT Foundation `,
      description: `The Open CSIRT Foundation proudly serves as an Observer on the 
      TrustBroker Africa Steering Committee, contributing its expertise in cybersecurity 
      incident response and fostering collaboration among key stakeholders in Africa.`, 
     
      linkedin: 'https://www.linkedin.com/company/open-csirt-foundation/',
      image: '/assets/images/opencsirt.jpeg'
    },
    { 
      name: 'Perpetus-Houngbo', 
      description: `Jacques Houngbo is a seasoned cybersecurity and digital transformation specialist known for his deep expertise 
      in protecting information systems and leading strategic digital initiatives across West Africa.`, 
      linkedin: 'https://www.linkedin.com/in/perpetusjacqueshoungbo/', 
      image: '/assets/images/Perpetus.jpg' 
    }
  ];

  return (
    <div className="steering-committee-container">
      <h2 className="headline">Steering Committee</h2>
      <div className="committee-members">
        {committeeMembers.map((member, index) => (
          <div key={index} className="member-card">
            <img src={member.image} alt={`${member.name}`} />
            <div className="member-name">
              <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                <h3>{member.name}</h3>
              </a>
              <p>{member.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SteeringCommittee;
