import React, { useState } from 'react' ;
import './Media.css'; 
import Button from 'react-bootstrap/Button';

function Media() {
  const [showMore, setShowMore] = useState(false);

  const handleReadMoreClick = () => {
    setShowMore(!showMore);
  };

  return (
    // <div className="media-page">
    //   <section className="blog-section">
    //    <img src="assets/images/NgREN_TBAIII.png" alt="Featured post" className="featured-image"/>
    //     <div className="featured-post">
          
    //       <h3 className="post-title">{' '}
    //       <a href="https://indico.wacren.net/event/179/" target="_blank" rel="noopener noreferrer">
    //       TBA III - TrustBroker Africa Workshop and Community Building Event holds in Abuja, Nigeria
    //     </a>{' '}
    //         </h3>
    //       <div className="post-meta">
    //         <span className="author">TBA Team</span> | <span className="time">2023-11-18 06:44</span>
    //       </div>
    //       <p className="post-excerpt">
    //       The third TrustBroker Africa cybersecurity training and CSIRT community-building event will take place at the National Universities Commission (NUC) in Abuja, Nigeria hosted by NgREN, the Nigerian Research and Education Network.
    //       </p>
    //       <p>The event is organised in collaboration with the OpenCSIRT Foundation and supported by the AfricaConnect3 project. The training will feature in-depth sessions on the core functions of CSIRTs, including incident handling and coordination of responses to security incidents.
    //       </p>
    //       <p>Special sessions will be dedicated to fostering cooperation within the host Nigerian community.
    //       </p>
    //       <ul>
    //         <li>Date: December 6-8, 2023</li>
    //         <li>Venue: Boardroom, National Universities Commission (NUC), 26, Aguiyi Ironsi Street, Maitama, Abuja.</li>
    //         <li>Registration: Apply {' '}
    //           <a href="https://indico.wacren.net/event/179/" target="_blank" rel="noopener noreferrer">
    //           here
    //          </a> {' '} 
            
    //           </li>
    //       </ul>
          
          
    //     </div>
    //     <div className="other-posts">
    //       <div className="post">
    //         <img src="assets/images/BOCRA_TBAII.png" alt="Post" className="post-image"/>
    //         <h4 className="post-title">{' '}
              
    //         <a href="https://indico.wacren.net/event/146//" target="_blank" rel="noopener noreferrer">
    //           TrustBroker Africa (TBA) II: CSIRT training and community-building workshop
    //           </a>{' '}
    //           </h4>
    //         <div className="post-meta">
    //           <span className="author">TBA Team</span> | <span className="time">2022-09-20 07:39</span>
    //         </div>
    //         <p className="post-excerpt">
    //         The second TrustBroker Africa cybersecurity training and CSIRT community-building event is organized in collaboration with the Open CSIRT Foundation and Cyber4Dev with the 
    //         support of the AfricaConnect3 project and hosted by Botswana Communications Regulatory Authority ({' '}
    //         <a href="https://www.bocra.org.bw/" target="_blank" rel="noopener noreferrer">
    //         BOCRA </a> {''}) 
    //         and {' '}
    //         <a href ="https://www.cirt.org.bw/" target="_blank" rel="noopener noreferrer">
    //         BwCIRT, 
    //         </a>{' '} the Computer Incident Response Team for Botswana.
    //         </p>
            
    //       </div>
    //       <div className="post">
    //         <img src="assets/images/tbaevent.jpeg" alt="Post" className="post-image"/>
    //         <h4 className="post-title">{' '}
    //         <a href="https://indico.wacren.net/event/124/" target="_blank" rel="noopener noreferrer">
    //         Africa Training Initiative: TBA CSIRT Workshop in Lome, Togo
    //     </a>{' '}
    //         </h4>
    //         <div className="post-meta">
    //           <span className="author">TBA Team</span> | <span className="time">2021-09-01 06:30</span>
    //         </div>
    //         <p className="post-excerpt">
    //         This cybersecurity training and CSIRT community-building event is organized in collaboration with the Open CSIRT Foundation 
    //         with the support of the AfricaConnect3 project and hosted by {' '}
    //         <a href="https://www.togorer.tg/" target="_blank" rel="noopener noreferrer">
    //         TogoRER </a> {' '}, the research and education network of Togo and {' '}
    //         <a href="https://www.cda.tg/enterprise-security-risk-management/" target="_blank" rel="noopener noreferrer">
    //          Cyber Defense Africa </a>{" "}, the cybersecurity services company mandated by the Togolese Republic to ensure the security of information systems in Togo and beyond its borders.
    //         </p>
    //          {showMore && (
    //     <div className="additional-content">
    //         <p>This foundation CSIRT Training is based on the well-known TRANSITS I training for people new at a CSIRT, for relatively new CSIRTs, and for those around a CSIRT such as communications or legal 
    //           who need to understand CSIRT activities. business is all about. It's also useful for CSIRT managers and will include a technical introduction 
    //           module (attack vectors and such), including underground economy aspects.
    //           </p>
    //           <ul>
    //         <li>Date: December 25-29, 2021</li>
    //         <li>Venue: Ministry of Digital Economy and Digital Transformation</li>
    //         <li>Registration: Open shortly. If you would like to be notified when this happens, send expression of interest to  {' '}
    //           <a href="mailto:training@wacren.net/" target="_blank" rel="noopener noreferrer">
    //           training@wacren.net
    //          </a> {' '} 
            
    //           </li>
    //       </ul> 
    //       </div>
    //       )}
    //       <Button className='dflex items-center justify-center text-center mb-4 btn' onClick={handleReadMoreClick}>
    //        {showMore ? 'Show Less' : 'Read More'}
    //      </Button>
    //      </div>
    //     </div>
        
    //   </section>
    <div className="media-page">
      <section className="events-section">
        <h2 className="section-title">Events</h2>
        <div className="events">
          <div className="event">
            <img src="/assets/images/wacren2021.png" alt="Event" className="event-image"/>
            <p className="event-caption"> {' '}
        <a href="https://indico.wacren.net/event/124/" target="_blank" rel="noopener noreferrer">
        TrustBroker Africa (TBA): CSIRT training and community-building workshop
        </a>{' '}
              
              </p>
            

            
          </div>
          <div className="event">
            <img src="/assets/images/BOCRA_TBAII.png" alt="Event" className="event-image"/>
            <p className="event-caption">{' '}
            <a href="https://indico.wacren.net/event/146//" target="_blank" rel="noopener noreferrer">
              TrustBroker Africa (TBA) II: CSIRT training and community-building workshop
              </a>{' '}
              </p>
            

            
          </div>
          <div className="event">
            <img src="/assets/images/NgREN_TBAIII.png" alt="Event" className="event-image"/>
            <p className="event-caption">{' '}
            <a href=" https://indico.wacren.net/event/179/" target="_blank" rel="noopener noreferrer">
            TBA III - TrustBroker Africa Workshop and Community Building Event holds in Abuja, Nigeria
              </a>{' '}
              
              </p>
           
          </div>
          
        </div>
      </section>

      {/* New Section Below Events */}

      <section className="media-section">
        <h2 className="section-title">Photos</h2>
        <div className="media-grid photos-grid">
          <div className="media-card">
            <img src="/assets/images/tba1.jpg" alt="Photo 1" className="media-image" />
            <p>{' '}
            <a href="  https://photos.wacren.net/index.php?/category/98" target="_blank" rel="noopener noreferrer">
            TBA III Workshop Abuja
              </a>{' '}
              </p>
           
           
          </div>
          
        </div>
      </section> 

    </div>
  );
}

export default Media;
