import React from 'react';
import "./App.css";

const TbaTemplateForm = () => {
  return (
    <div className="tba-template-form">
      <h2>TBA Template: list_v32.txt</h2>
      <p><strong>Version 3.2 of 1 March 2021</strong></p>
      
      <p>Send as Electronic Mail to the TBA as <strong>*an ASCII file!*</strong></p>
      <p>PDF, WORD or OPENOFFICE documents will not be processed.</p>
      <p>
        In addition to this text file you need to submit appropriate PGP/GPG public key files for 
        <strong>*all*</strong> keys referenced. Preferably these keys are signed by the team representatives, 
        a team key or potential sponsors.
      </p>
      <p>
        You will find our contact data and public PGP/GPG key on: 
        <a href="https://www.trustbroker-dev.ren.africa/support" target="_blank" rel="noopener noreferrer">
          https://www.trustbroker-dev.ren.africa/support
        </a>
      </p>
      <p>
        Please note: All information provided will be considered public and published on the TBA web site 
        for the listing process. If your team is accepted, this information will be available while you are TBA Listed 
        and your team representatives will be able to maintain and edit the information using the TBA team update self-service.
      </p>
      <p>
        All team-related information will be available from: 
        <a href="https://www.trustbroker-dev.ren.africa/registry" target="_blank" rel="noopener noreferrer">
          https://www.trustbroker-dev.ren.africa/registry
        </a>
      </p>

      <hr />

      <h3>Submitter Information:</h3>
      <p>
        Contact information of the person submitting this request either on behalf of the team itself, but also 
        other persons like representatives of the constituency are also allowed to submit a listing request!
      </p>

      <form className="submitter-form">
        <div className="form-group">
          <label>First and last name:</label>
          <input type="text" name="name" />
        </div>

        <div className="form-group">
          <label>Email address:</label>
          <input type="email" name="email" />
        </div>

        <div className="form-group">
          <label>PGP fingerprint:</label>
          <input type="text" name="pgpFingerprint" />
        </div>

        <div className="form-group">
          <label>Phone number including country code:</label>
          <input type="tel" name="phoneNumber" />
        </div>

        <div className="form-group">
          <label>Describe your role in which capacity you submitted this request:</label>
          <textarea name="role" rows="3" />
        </div>

        <p>Please provide the information as complete as possible. Do submit the PGP/GPG Keys as separate files, include only PGP/GPG Key IDs here.</p>

        <hr />

        <h3>Section 1) Team Name:</h3>

        <div className="form-group">
          <label>Acronym used (short name):</label>
          <input type="text" name="acronym" placeholder="" />
        </div>

        <div className="form-group">
          <label>Official team name:</label>
          <input type="text" name="teamName" placeholder="" />
        </div>

        <div className="form-group">
          <label>Host organisation:</label>
          <input type="text" name="hostOrg" placeholder=" " />
        </div>

        <div className="form-group">
          <label>Main Country:</label>
          <input type="text" name="mainCountry" placeholder="" />
        </div>

        <div className="form-group">
          <label>Additional countries:</label>
          <input type="text" name="additionalCountries" placeholder="" />
        </div>

        <div className="form-group">
          <label>Team Establishment Date:</label>
          <input type="date" name="establishmentDate" />
        </div>

        <hr />

        <h3>Section 2) Type of Team:</h3>

        <div className="form-group">
          <label>Type (check one or more):</label>
          <div className="checkbox-group">
            <label><input type="checkbox" name="type" value="CIIP" /> CIIP</label><br />
            <label><input type="checkbox" name="type" value="Commercial Organisation" /> Commercial Organisation</label><br />
            <label><input type="checkbox" name="type" value="Financial Sector" /> Financial Sector</label>< br />
            <label><input type="checkbox" name="type" value="Government" /> Government</label>< br />
            <label><input type="checkbox" name="type" value="International Body" /> International Body</label>< br />
            <label><input type="checkbox" name="type" value="ISP Customer Base" /> ISP Customer Base</label>< br />
            <label><input type="checkbox" name="type" value="Law Enforcement" /> Law Enforcement</label>< br />
            <label><input type="checkbox" name="type" value="Military" /> Military</label>< br />
            <label><input type="checkbox" name="type" value="National" /> National</label>< br />
            <label><input type="checkbox" name="type" value="Non-Commercial Organisation" /> Non-Commercial Organisation</label>< br />
            <label><input type="checkbox" name="type" value="Research & Education Network" /> Research & Education Network</label>< br />
            <label><input type="checkbox" name="type" value="Service Provider Customer Base" /> Service Provider Customer Base</label>< br />
            <label><input type="checkbox" name="type" value="Vendor Customer Base" /> Vendor Customer Base
</label>
          </div>
        </div>

        <div className="form-group">
          <label>Other type of team:</label>
          <input type="text" name="otherType" placeholder="" />
        </div>

        <p>
          Some explanation to the above list that should allow a better choice on your side:
          <ul>
            <li>ISP Customer Base covers only commercial sector</li>
            <li>Government teams include law enforcement</li>
            <li>Vendor teams are responsible for the security of their products, not their internal security teams</li>
            <li>Service Customer Based teams are those that offer incident response and strongly to incident response-related services.</li>
            <li>They are usually serving as "outsourced" CSIRT for their
            customers.</li>
          </ul>
        </p>

        <hr />

        <h3>Section 3) Description of the constituency:</h3>

        <div className="form-group">
          <label>Based on a description of Internet Domains, IP Address Information and/or other suitable characterization of the constituency:</label>
          <textarea name="constituency" rows="5" placeholder="" />
        </div>

        <hr />

        <h3>Section 4) Contact Information:</h3>

        <div className="form-group">
          <label>Postal Mailing Address of the team including country:</label>
          <textarea name="postalAddress" rows="3" placeholder="" />
        </div>
        <div className="form-group">
          <label>Team Email address:</label>
          <input type="email" name="email" />
        </div>
        <div className="form-group">
          <label>Phone number including country code:</label>
          <input type="tel" name="primaryPhone" placeholder="+" />
        </div>
        <div className="form-group">
          <label>Emergency phone number including country code:</label>
          <input type="tel" name="primaryPhone" placeholder="+" />
        </div>
        <div className="form-group">
          <label>PGP fingerprint:</label>
          <input type="text" name="primaryPgp" placeholder="0x" />
        </div>
        <div className="form-group">
        <label>World Wide Web server:</label>
        <input type="text" name="webServer" placeholder=" " />
      </div>

      <div className="form-group">
        <label>Business hours:</label>
        <input type="text" name="businessHours" placeholder=" " />
      </div>

      <div className="form-group">
        <label>Time-zone (relative to GMT):</label>
        <input type="text" name="timeZone" placeholder=" "/>
      </div>
      <hr />
        <div className="team-representatives-form">

      <h3>Section 5) Team Representatives</h3>
      <p>
        Please name two Team Representatives! Both have the same rights in regard to updating the team data 
        and speak authoritatively for the team!
      </p>

      <div className="form-section">
        <h3>* Primary Team Representative</h3>
        <div className="form-group">
          <label>First and last name:</label>
          <input type="text" name="primaryName" placeholder="" />
        </div>

        <div className="form-group">
          <label>Email address:</label>
          <input type="email" name="primaryEmail" placeholder="" />
        </div>

        <div className="form-group">
          <label>PGP fingerprint:</label>
          <input type="text" name="primaryPgp" placeholder="0x" />
        </div>

        <div className="form-group">
          <label>Phone number including country code:</label>
          <input type="tel" name="primaryPhone" placeholder="+" />
        </div>
      </div>

      <div className="form-section">
        <h3>* Secondary Team Representative</h3>
        <div className="form-group">
          <label>First and last name:</label>
          <input type="text" name="secondaryName" placeholder="" />
        </div>

        <div className="form-group">
          <label>Email address:</label>
          <input type="email" name="secondaryEmail" placeholder="" />
        </div>

        <div className="form-group">
          <label>PGP fingerprint:</label>
          <input type="text" name="secondaryPgp" placeholder="0x" />
        </div>

        <div className="form-group">
          <label>Phone number including country code:</label>
          <input type="tel" name="secondaryPhone" placeholder="+" />
        </div>
      </div>

      <hr />

      <h3>Section 6) Memberships</h3>

      <div className="form-group">
        <p>If the team is a member of FIRST, please provide the following information:</p>
        <label>Full member since:</label>
        <input type="text" name="firstMembership" placeholder="" />
      </div>

      <div className="form-group">
        <label>If the team is a member of TF-CSIRT/TI please provide the following information:</label>
        <div className="form-group">
          <label>Listed since:</label>
          <input type="text" name="tfCsirtListedSince" placeholder=" " />
        </div>
        <div className="form-group">
          <label>Accredited since:</label>
          <input type="text" name="tfCsirtAccreditedSince" placeholder=" " />
        </div>
      </div>

      <div className="form-group">
        <label>If the team is a member of any national CERT related forum or organisation please provide:</label>
        <div className="form-group">
          <label>Name:</label>
          <input type="text" name="certForumName" placeholder=" " />
        </div>
        <div className="form-group">
          <label>URL public information:</label>
          <input type="text" name="certForumURL" placeholder=" " />
        </div>
      </div>

      <hr />

      <h3>Section 7) Cooperation and Collaboration</h3>
      <p>
        Please provide here some basic information for other CSIRTs etc. that you work with or that would vouch for you.
        (Please add those information in free form as you see fit. At least the team name, web page and point of contact is needed!)
      </p>
      
      <div className="form-group">
        <textarea name="cooperationAndCollaboration" rows="5" placeholder=" " />
      </div>
    </div>
      </form>
    </div>
  );
};

export default TbaTemplateForm;
