import React from 'react';
import './Join.css'; // Ensure you import the CSS file
import { Link } from 'react-router-dom';

function AccreditedTeam() {
  return (
    <div className="accredited-team-page">
      <h1 className="title">Joining TrustBroker Africa as <span className="highlight">TBA Accredited team</span></h1>
      <p className="description">
        Only already <Link to="/join/listed-team" className="link">"listed"</Link> teams can become accredited. 
        Any listed team that is serious about its service can gain accreditation. Accreditation is performed by the 
        TBA team following a standardised process which takes between one and four months, depending on the current 
        status and preparation as well as the feedback received during this process. <br />  
      </p>
      <p>Currently, for the duration of the AfricaConnect3 project, no fees are charged! The accreditation 
        process will start as soon as a sufficient number of African teams are listed. Additional conditions and timeline will be discussed and decided within the TBA community and by the TBA Steering Committee.
      </p>
      <div className="image-placeholder">
        <img src="/assets/images/tba_accrediting.png" alt="Placeholder for Accredited Team" />
      </div>
      <div className="steps-section">
        <h2>To apply for accreditation every team needs to follow these steps:</h2>
        <ol>
          <li>If a team is not yet listed, it must <Link to="/join/listed-team" className="link">apply for this first.</Link> </li>
          <li>To apply for accreditation, the team just needs to send a simple request to the <Link to="/support" className="link">TBA team.</Link></li>
          <li>The TBA team will start the accreditation process by sending a signature page to the potential candidate. This page must be signed and shows the 
            formal acceptance of the process by the team.</li>
          <li>Within four weeks after becoming invited the formal acceptance of the process requirements need to be signed and then send by e-mail or 
            postal mail to the TBA team. Once the signature has been received,
             the formal status of the team will changed to "accreditation candidate" and the TBA community will be notified accordingly.</li>
          <li>Starting from the recognition as candidate the team has three months to update the team data using the 
          <Link to="/join/accredited-team" className="link"> TBA Self-Service.</Link> outlining the required statements and information.</li>
          <li>The TBA team will verify and assess all the information that have been put into the TBA database by the candidate and may ask additional questions. 
            This verification and feedback cycle usually takes one to four weeks on average, depending on the level of interaction. Please be advised that the successful 
            verification of the candidate must be finalised within the available three months. </li>
          <li>Once the TBA team has verified that the candidate has met all requirements, the status of the team is changed from "listed" to "accredited". 
            The candidate as well as the TBA community are formally notified thereof. 
            With the new status the team gains access to the complete set of TBA Services.</li>
          <li>If at any stage during this process the candidate does not meet the requirements or misses the deadlines, the invitation will expire or 
            the accreditation process will fail. Any team can only be invited twice within twelve month.</li>

        </ol>
      </div>
      <div className="related-materials">
        <h2>Related Materials</h2>
        <p className="related-description">
         Materials of interest for this process are:
        </p>
        <ul>
          <li>none so far
          </li>
          </ul>
      </div>
    </div>
  );
}

export default AccreditedTeam;
