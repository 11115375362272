import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import './Support.css'; 

function Support() {
  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  // const [email, setEmail] = useState('');
  // const [message, setMessage] = useState('');

  // const handleFormSubmit = (event) => {
  //   event.preventDefault();
  //   // Handle form submission logic, e.g., sending data to a backend
  //   console.log({ firstName, lastName, email, message });
  // };

  return (
    <div className="support-container">
      
      <div className="confidentiality-email">
      <h2>Confidentiality and Authenticity of Email Communication</h2>
      <p>
        To send confidential data encrypted to the TBA team use the following public key, available through well-known public PGP keyservers:
      </p>

      <div className="pgp-box">
        <pre>
          User ID: TrustBroker Africa (TBA) ** TICKET SYSTEM ** {"\n"}
          tba@trustbroker.africa {"\n"}
          Key ID: 966D5533 {"\n"}
          Key type: RSA {"\n"}
          Key size: 4096 {"\n"}
          Fingerprint: 5A70 453F 2E00 FF3C 36B7 7CBB 014F 2864 966D 5533
        </pre>
      </div>

      <p>
        The TBA team uses several GPG/PGP keys, whenever authenticity is essential. The key used to sign all of them is called "Master Signing" key! You can download it from well-known public PGP keyservers:
      </p>

      <div className="pgp-box">
        <pre>
          User ID: TrustBroker Africa (TBA) ** MASTER SIGNING ** {"\n"}
          Key ID: C1AEC76D {"\n"}
          Key type: RSA {"\n"}
          Key size: 4096 {"\n"}
          Fingerprint: 5474 A6FE B846 5ACB EC5C 3A75 9510 5F46 C1AE C76D
        </pre>
      </div>
    </div>
      <div className="contact-info">
        <div className="contact-item">
          <div className="image-overlay">
            <img src="assets/images/address.png" alt="Address" className="contact-image" />
            <div className="overlay">
             
              <ul>
              <li> TrustBroker Africa Service </li>
               <li> C/o WACREN </li>
               <li> VCG Office Complex, IPS Road </li>
               <li> P O Box LG 1279, Accra, Ghana </li>
               </ul>
            </div>
          </div>
        </div>
        
        <div className="contact-item">
          <div className="image-overlay">
            <img src="assets/images/Email.jpg" alt="" className="contact-image" />
            <div className="overlay">
              <ul>
                <li>
              {' '}
            <a href ="mailto:tba@trustbroker.africa" target="_blank" rel="noopener noreferrer">
            tba@trustbroker.africa
            </a>{' '} </li>
            </ul>
            </div>
          </div>
        </div>
      </div>
      

      <div className="service-governance">
      <h2>Service Governance</h2>
      <p>
        The TrustBroker Africa Service is operated by <a href="https://www.wacren.net" target="_blank" rel="noopener noreferrer">WACREN</a>, 
        in partnership with sister regional networks, <a href="https://www.ubuntunet.net" target="_blank" rel="noopener noreferrer">Ubuntunet Alliance</a> 
        and <a href="https://www.asrenorg.net" target="_blank" rel="noopener noreferrer">ASREN</a> as part of the 
        <a href="https://africaconnect3.net" target="_blank" rel="noopener noreferrer"> AfricaConnect3 project</a> which is co-funded by the European Union.
      </p>
      <p>
        The TBA Steering Committee coordinates the activities of the TrustBroker Africa Service with the co-operation with other well-known members of the community.
      </p>
      <p>The tasks of the TBA Steering Committee are as follows:</p>
      <ul>
        <li>Making decisions on team listing and accreditation as well as associates in accordance with the guidelines developed for and by the TBA community.</li>
        <li>Reviewing the performance of the TrustBroker Africa Service and making recommendations to modify or expand it.</li>
      </ul>
      <div className="service-manager">
      <h3>Service Manager</h3>
      <p>
        The service manager responsible for the service delivery is <a href="https://www.linkedin.com/in/omooaiya/" target="_blank" rel="noopener noreferrer">Omo Oaiya</a>. 
        In case of special requests or service escalations please contact him directly. If needed, use 
        <a href="/Omo keys.odt" target="_blank" rel="noopener noreferrer"> his PGP key</a> to encrypt any email sent to him.
      </p>
    </div>
    </div>

      <div className="faq-container">
        <h3 className="subtitle">FAQs</h3>
        <div className="faq-section">
          <p className="faq-prompt">Ask your questions here</p>
          <input type="text" placeholder="Search here" className="form-control search-input" />
          <div className="faq-item">
            <h4>-</h4>
            <p>-</p>
          </div>
          {/* Add more FAQ items as needed */}
        </div>
      </div>
    </div>
  );
}

export default Support;



