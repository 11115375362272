import React from 'react';
import './Join.css'; 
import { Link } from 'react-router-dom';

function ListedTeam() {
  return (
  
    <div className="listed-team-page">
      <h1 className="title">Joining TrustBroker Africa as a Listed team</h1>
      <p className="description">
      <strong>If your team would like to become part of the TBA community:</strong> Your team needs 
      to be registered or "listed" to achieve this.Currently, the TBA Steering Committee undertakes the 
      vetting responsibility until Accredited teams are established.
      </p>
      <p>Otherwise, the process is simple enough and once you have secured your sponsors it will take 4 to 5 
        weeks to conclude a vote of the TBA and inform you about the outcome.
      </p>
      <p>For now, please:
      </p>
      <ol>
        <li>fill out the {' '}
        <a href="/tbatemplateform" target="_blank" rel="noopener noreferrer">
        form 
        </a>{' '} for new listing candidates</li>
        <li>submit the filled out form with the relevant PGP/GPG public keys to <Link to="/support" className="link">
                  tba@trustbroker.africa
        </Link> 
        </li>
      </ol>
      <p>If your team would like to understand more about the process: Read on and have a look at the process!
      </p>

      <div className="image-placeholder">
        <img src="/assets/images/tba_listing.png" alt="Placeholder for Listed Team" />
      </div>
      <div className="process-section">
        <h2>The Process for new Listing Candidates</h2>
        <p>
          The TBA team encourages all applying teams to engage already listed TBA teams to solicit their support!
          After a team has applied for listing as summarised above the process continues:
        </p>
        <ul>
          <li>The listing application is distributed inside the TBA community.</li>
          <li>Within three weeks the applying candidate team needs to have explicit support of the TBA Steering Committee. (This will change in the future once there are enough TBA Accredited teams, in which case a sponsorship process and voting will be introduced)</li>
          <li>Additionally no objections must be raised within the TBA community. Any member from the TBA community, i.e. listed teams, accredited teams, TBA Associates, can object against a candidate. Objections will be handled with care - the TBA team will negotiate the needed confidentiality with the objecter.</li>
          <li>The TBA team informs the TBA community of the outcome of the application.</li>
          <li>Rejected teams do have the right to re-apply right after they receive the outcome message, although we recommend to take some time to discuss with potential sponsoring teams any new registration application to be sure, the voting passed in favour of your team the next time.</li>
          <li>After three or more consecutive rejections any new registration application will be automatically rejected without any consideration.</li> 
          <li>If a automatically rejected team believes that the situation or circumstances (causing the rejection) has changed significantly, it might request a decision of the TBA Steering Committee to allow a new registration application. It is important that the team explains what has changed and why it is likely that the team will be accepted this time.</li>
        </ul>

      </div>
      <div className="listedteam-page">
      <h3>Re-Listing every three years</h3>
      <p>
        To increase the default trust level within the TBA Community, all "listed" teams that have <strong>not become</strong> "accredited" within 
        three years after their initial registration or the last successful "re-listing" are required to be accepted for another three year period. 
        Again this requires explicit support by the TBA community. If there is no support, the team will be removed and no longer shown as "listed". For the re-listing the same procedure as for the "listing" is used.
      </p>
      <div className="image-placeholder">
        <img src="/assets/images/tba_relisting.png" alt="Placeholder for Re-Listing" />
      </div>
      <p>In addition, the procedures require any re-listing candidate to meet the following conditions:</p>
      <ul>
        <li>having two people registered as team representatives -- two for resiliency, each of those must have a valid e-mail address and valid PGP/GPG key</li>
        <li>valid team e-mail address and PGP/GPG key for the team</li>
        <li>latest update or up-to-date notice of team information within the TBA Directory not older than 12 months</li>
      </ul>
      <p>The TBA team encourages all listed teams to attend TBA and other relevant meetings to meet other teams and secure the on-going support of the community!
      </p>
      <div className="related-materials">
         <h4>Related Materials</h4>
        <p>
        Useful materials for this process are:</p>
        <ul>
          <li>form for registering new listing candidates
          </li>
        </ul>
      </div>
    </div>
    </div>
   
  );
}

export default ListedTeam;


