import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import './About.css';

function Resources() {
  return (
    <div className="container">
      <h1>Resources</h1>

      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Type</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <a href="/CSIRT_setting_up_guide_ENISA.pdf" target="_blank" rel="noopener noreferrer">
               CSIRT setting up guide.
              </a>
            </td>
            <td>Policies</td>
            <td>2024</td>
          </tr>
          <tr>
            <td>
              <a href="/ngCERT role in Cybersecurity EDITED.pdf" target="_blank" rel="noopener noreferrer">
                NGCERT role in Cybersecurity.
              </a>
            </td>
            <td>Policies</td>
            <td>2024</td>
          </tr>
          <tr>
            <td>
              <a href="/TRANSIT I - Organisational Module_Mirek.pptx" target="_blank" rel="noopener noreferrer">
                TRANSIT I Organisational Module
              </a>
            </td>
            <td>Policies</td>
            <td>2024</td>
          </tr>
          <tr>
            <td>
              <a href="/TRANSITS I - Operational Module.pdf" target="_blank" rel="noopener noreferrer">
              TRANSITS I Operational Module
              </a>
            </td>
            <td>Policies</td>
            <td>2024</td>
          </tr>
          <tr>
            {/* <td>
              <a href="/policies.pdf" target="_blank" rel="noopener noreferrer">
                The TrustBroker Africa Service forms the trusted backbone of infrastructure services and serves as a clearinghouse for all security and incident response teams.
              </a>
            </td> */}
            {/* <td>Policies</td>
            <td>2024</td> */}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Resources;
