// import React from 'react'
import React, { useState } from "react";
import { Routes, Route, Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import './About.css'; 

function TrustBrokerAfrica() {

  const [showMore, setShowMore] = useState(false);

  const handleReadMoreClick = () => {
    setShowMore(!showMore);
  };

  return (
    <><div className="about-container">
      {/* <div className="image-section">
      <img src={require('./images/libsense.png').default} alt="Descriptive alt text" />
    </div> */}
      <h2 className="headline">TrustBroker Africa</h2>
      <p className="descriptions">TrustBroker Africa (TBA) is a community and a service designed to enhance cybersecurity capabilities across Africa,
        particularly focusing on training, certification, and collaboration among cybersecurity professionals and organizations.
        It likely operates within the context of building trust and security in digital networks, with an emphasis on incident response and management.
      
       </p>
      <p>TBA aims to improve the maturity and effectiveness of Computer Security Incident Response Teams (CSIRTs) and other cybersecurity 
        entities across the continent. They often offer specialized training and certification, such as the SIM3 Auditor Certification, 
        to help organizations align with international standards and best practices in cybersecurity.
      </p>
      <p> By fostering collaboration and providing advanced training, TrustBroker Africa contributes to 
        building a more secure digital environment in Africa, supporting national, NREN, higher education, 
        and commercial sectors. The specific details of TrustBroker Africa, such as its founding organizations or exact scope,
         would depend on the particular program or partnership behind it.
                </p>
    </div>
    {/* {showMore && (
              <div className="additional-content">
                
        </div>
      )}
    <Button className="read-more" onClick={handleReadMoreClick}>
        {showMore ? "Read less" : "Read more"}
      </Button> */}
      </>
     
      );

}

export default TrustBrokerAfrica;



